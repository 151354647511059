import Layout from "@/layout";
export default [
  {
    order: 1,
    path: "/home-bak",
    component: Layout,
    meta: {
      title: "首页",
      icon: "icon-shouye",
      hiddenCrumb: true,
    },
    redirect: "/home-bak",
    children: [
      {
        path: "/home-bak",
        name: "home",
        meta: {
          title: "首页",
          keepAlive: true,
          permissions: ["monitor:homepage"],
        },
        component: () => import("@/views/home/index.vue"),
      },
    ],
  },
  {
    order: 1,
    path: "/water-home",
    component: Layout,
    meta: {
      title: "首页",
      icon: "icon-shouye",
      hiddenCrumb: true,
    },
    redirect: "/water-home",
    children: [
      {
        path: "/water-home",
        name: "waterHome",
        meta: {
          title: "首页",
          keepAlive: false,
          permissions: ["monitor:water:homepage"],
        },
        component: () => import("@/views/home/water.vue"),
      },
    ],
  },
  {
    order: 1,
    path: "/",
    component: Layout,
    meta: {
      title: "看板",
      icon: "icon-shouye",
      hiddenCrumb: true,
    },
    redirect: "/",
    children: [
      {
        path: "",
        name: "home",
        meta: {
          title: "看板",
          keepAlive: true,
          permissions: ["monitor:board"],
        },
        component: () => import("@/views/billboard/index.vue"),
      },
    ],
  },
  {
    path: "/big-screen",
    name: "bigScreen",
    meta: {
      title: "大屏",
      keepAlive: true,
      hidden: true,
      hiddenButCanEnter: true,
      permissions: ["monitor:data-screen"],
    },
    component: () => import("@/views/bigScreen/index.vue"),
  },
];
