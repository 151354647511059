<template>
  <div class="group-detail-item">
    <div
      class="group-detail-name"
      :style="{
        width: labelWidth ? labelWidth + 'px' : '85px',
      }"
    >
      <p>{{ name + "：" }}</p>
    </div>
    <div
      class="group-detail-info"
      :style="{
        width: labelWidth
          ? `calc(100% - ${labelWidth}px)`
          : 'calc(100% - 85px)',
      }"
    >
      <div><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupDetailItem",
  componentName: "GroupDetailItem",
  props: {
    name: String,
    isDouble: Boolean,
    isEllipsis: Boolean,
    labelWidth: [Number, String],
  },

  data() {
    return {
      clientWidth: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.group-detail-item {
  width: 25%;
  margin-top: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  padding-right: 20px;
  .group-detail-name,
  .group-detail-info {
    line-height: 21px;
  }
  .group-detail-name {
    color: #666;
  }
  .group-detail-info {
    div {
      color: #333;
      p {
        word-break: break-all;
      }
    }
  }
}
</style>
