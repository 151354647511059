import store from "@/store";
import { isArray, isString } from "@/utils/validate";

function checkPermission(el, binding) {
  const { value } = binding;
  if (!value) return;
  const permissions = store.getters?.permissions || [];
  if (isArray(value)) {
    const hasPermission = permissions.some((role) => value.includes(role));
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  } else if (isString(value)) {
    if (!permissions.includes(value)) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding);
  },
  update(el, binding) {
    checkPermission(el, binding);
  },
};
