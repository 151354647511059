<template>
  <div class="status-card">
    <div
      class="status-card-item"
      v-for="(item, index) in cardData.list"
      :key="index"
      :style="{
        width: `calc(calc(100% - ${20 * (cardData.number - 1)}px) / ${
          cardData.number
        })`,
        'margin-left': (index + 1) % cardData.number == 1 ? '0' : '20px',
      }"
      :class="[
        item.specColor && item.value == item.specColor.condition
          ? item.specColor.color
          : '',
      ]"
    >
      <div class="field-name">
        <i class="iconfont" :class="item.icon"></i>
        <span>{{ item.label }}</span>
      </div>
      <!-- 增加过滤器处理 -->
      <div class="field-value">
        {{
          item.unit && !isBlank(item.value)
            ? item.value + item.unit
            : item.value
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { isBlank } from "@/utils/validate.js";
export default {
  name: "gc-status-card",
  props: {
    cardData: {
      type: Object,
      default: () => {
        return {
          number: 4, //每行的个数
          list: [],
        };
      },
    },
  },
  methods: {
    isBlank,
  },
};
</script>
<style lang="scss" scoped>
.status-card {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  &-item {
    height: 100px;
    background: linear-gradient(135deg, #f5f6fc 0%, #edeffa 100%);
    border-radius: 6px;
    margin-top: 20px;
    padding: 25px 0 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .field-name {
      i {
        font-size: 16px;
        vertical-align: middle;
        color: #4d6bff;
      }
      span {
        font-size: 16px;
        color: #4e4e4e;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
    .field-value {
      font-size: 22px;
      font-weight: 600;
      color: #4d6bff;
    }
  }
  .status-card-item.red {
    background: linear-gradient(135deg, #faf7f6 0%, #f5eeeb 100%);
    .field-name {
      i {
        color: #e5662e;
      }
    }
    .field-value {
      color: #e5662e;
    }
  }
}
</style>
