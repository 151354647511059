import { Notification } from "element-ui";

let instance = null;

const notify = (options) => {
  if (instance) instance.close();
  const defaultOpts = {
    title: "提示",
    type: "warning",
    position: "top-right",
    offset: 60,
    duration: 3000,
  };
  if (typeof options === "string") {
    options = {
      ...defaultOpts,
      message: options,
    };
  } else {
    options = {
      ...defaultOpts,
      ...options,
    };
  }
  instance = Notification(options);
};

export default notify;
