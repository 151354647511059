// 本地数据字典，为数据字典默认值

export default {
  alarmLevel: [
    {
      sortName: "告警级别",
      sortCode: "alarmLevel",
      name: "一般",
      defaultValue: "1",
      sortNo: 1,
    },
    {
      sortName: "告警级别",
      sortCode: "alarmLevel",
      name: "中等",
      defaultValue: "2",
      sortNo: 2,
    },
    {
      sortName: "告警级别",
      sortCode: "alarmLevel",
      name: "严重",
      defaultValue: "3",
      sortNo: 3,
    },
  ],
  commandStatus: [
    {
      sortName: "指令状态",
      sortCode: "commandStatus",
      name: "待发送",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "指令状态",
      sortCode: "commandStatus",
      name: "成功",
      defaultValue: "1",
      sortNo: 2,
    },
    {
      sortName: "指令状态",
      sortCode: "commandStatus",
      name: "失败",
      defaultValue: "2",
      sortNo: 3,
    },
  ],
  alarmStatus: [
    {
      sortName: "告警状态",
      sortCode: "alarmStatus",
      name: "告警中",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "告警状态",
      sortCode: "alarmStatus",
      name: "已恢复",
      defaultValue: "1",
      sortNo: 2,
    },
  ],
  alarmAckStatus: [
    {
      sortName: "告警处理结果",
      sortCode: "alarmAckStatus",
      name: "未处理",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "告警处理结果",
      sortCode: "alarmAckStatus",
      name: "已处理",
      defaultValue: "1",
      sortNo: 2,
    },
  ],
  alarmSeq: [
    {
      sortName: "告警状态时序",
      sortCode: "alarmSeq",
      name: "实时",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "告警状态时序",
      sortCode: "alarmSeq",
      name: "历史",
      defaultValue: "1",
      sortNo: 2,
    },
  ],
  operatorType: [
    {
      sortName: "操作类型",
      sortCode: "operatorType",
      name: "设备注册",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "操作类型",
      sortCode: "operatorType",
      name: "设备安装",
      defaultValue: "1",
      sortNo: 2,
    },
    {
      sortName: "操作类型",
      sortCode: "operatorType",
      name: "设备拆除",
      defaultValue: "2",
      sortNo: 3,
    },
  ],
  deviceStatus: [
    {
      sortName: "设备状态",
      sortCode: "deviceStatus",
      name: "待安装",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "设备状态",
      sortCode: "deviceStatus",
      name: "已安装",
      defaultValue: "1",
      sortNo: 2,
    },
    {
      sortName: "设备状态",
      sortCode: "deviceStatus",
      name: "已拆除",
      defaultValue: "2",
      sortNo: 3,
    },
  ],
  connectType: [
    {
      sortName: "连接类型",
      sortCode: "connectType",
      name: "直连设备",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "连接类型",
      sortCode: "connectType",
      name: "网关设备",
      defaultValue: "1",
      sortNo: 2,
    },
    {
      sortName: "连接类型",
      sortCode: "connectType",
      name: "网关子设备",
      defaultValue: "2",
      sortNo: 3,
    },
  ],
  queryType: [
    {
      sortName: "查询类型",
      sortCode: "queryType",
      name: "本周",
      defaultValue: "0",
      sortNo: 1,
    },
    {
      sortName: "查询类型",
      sortCode: "queryType",
      name: "本月",
      defaultValue: "1",
      sortNo: 2,
    },
    {
      sortName: "查询类型",
      sortCode: "queryType",
      name: "本年",
      defaultValue: "2",
      sortNo: 3,
    },
    {
      sortName: "查询类型",
      sortCode: "queryType",
      name: "自定义",
      defaultValue: "3",
      sortNo: 4,
    },
  ],
  pushType: [
    {
      sortName: "告警生成推送列表",
      sortCode: "pushType",
      name: "员工微信推送",
      defaultValue: "1",
      sortNo: 1,
    },
    {
      sortName: "告警生成推送列表",
      sortCode: "pushType",
      name: "员工短信推送",
      defaultValue: "2",
      sortNo: 2,
    },
    {
      sortName: "告警生成推送列表",
      sortCode: "pushType",
      name: "页面推送",
      defaultValue: "3",
      sortNo: 3,
    },
    {
      sortName: "告警生成推送列表",
      sortCode: "pushType",
      name: "员工电话推送",
      defaultValue: "4",
      sortNo: 4,
    },
    {
      sortName: "告警生成推送列表",
      sortCode: "pushType",
      name: "设备用户微信推送",
      defaultValue: "5",
      sortNo: 5,
    },
    {
      sortName: "告警生成推送列表",
      sortCode: "pushType",
      name: "设备用户短信推送",
      defaultValue: "6",
      sortNo: 6,
    },
    {
      sortName: "告警生成推送列表",
      sortCode: "pushType",
      name: "设备用户电话推送",
      defaultValue: "7",
      sortNo: 7,
    },
  ],
  notifyType: [
    {
      sortName: "推送类型",
      sortCode: "notifyType",
      name: "微信",
      defaultValue: "1",
      sortNo: 1,
    },
    {
      sortName: "推送类型",
      sortCode: "notifyType",
      name: "短信",
      defaultValue: "2",
      sortNo: 2,
    },
  ],
};
