import { setLS, getLS } from "@/utils/storage";
import { asyncRoutes } from "@/router/router.config";
import { getAffixTags, filterAsyncRoutes } from "@/utils/app";

export default {
  state: {
    fold: getLS("fold") ?? true, // 左侧菜单是否折叠
    routes: getLS("routes") || [], // 菜单路由列表
    curRoute: getLS("curRoute") || "home", // 记录当前所在路由（一级路由name）
    tags: getLS("tags") || [], // 标签页列表
  },
  getters: {
    fold: (state) => state.fold,
    routes: (state) => state.routes,
    curRoute: (state) => state.curRoute,
    tags: (state) => state.tags,
  },
  mutations: {
    SET_FLOD: (state, flag) => {
      setLS("fold", flag);
      state.fold = flag;
    },
    SET_ROUTES(state, routes) {
      setLS("routes", routes);
      state.routes = routes;
    },
    SET_CUR_ROUTE(state, payload) {
      setLS("curRoute", payload);
      state.curRoute = payload;
    },
    UPDATE_TAGS(state, payload) {
      setLS("tags", payload);
      state.tags = payload;
    },
  },
  actions: {
    // 根据权限生成路由列表
    generateRoutes({ commit, rootGetters }) {
      const permissions = rootGetters?.permissions || [];
      return new Promise((resolve) => {
        const accessedRoutes = filterAsyncRoutes(
          asyncRoutes,
          permissions,
          "/",
          rootGetters.userInfo.tenantType
        );
        commit("SET_ROUTES", accessedRoutes);
        commit("UPDATE_TAGS", getAffixTags(accessedRoutes));
        resolve(accessedRoutes);
      });
    },
    // 新增标签页
    addTags({ state, commit }, tag) {
      const exist = state.tags.some((route) => route.fullPath === tag.fullPath);
      if (exist) return;
      // eslint-disable-next-line no-unused-vars
      const { matched, ...args } = tag;
      const newTags = [
        ...state.tags,
        {
          ...args, // 去除matched项，matched包含循环引用的对象，不能使用JSON.stringify(setLS)
          title: tag.query?.title || tag.meta.title,
        },
      ];
      commit("UPDATE_TAGS", newTags);
    },
    // 删除标签页
    deleteTags({ state, commit }, tag) {
      const newTags = state.tags.filter(
        (route) => route.fullPath !== tag.fullPath
      );
      return new Promise((resolve) => {
        commit("UPDATE_TAGS", newTags);
        resolve(newTags);
      });
    },
  },
};
