<template>
  <gc-dialog
    title="切换组织"
    :show.sync="visible"
    :show-close="false"
    :show-cancel-btn="false"
    :close-on-press-escape="false"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
  >
    <template v-if="orgList.length">
      <el-radio
        v-for="item in orgList"
        :key="item.id"
        v-model="radio"
        :label="item.id"
        >{{ item.name }}</el-radio
      >
    </template>
    <p v-else>请创建营业厅！</p>
  </gc-dialog>
</template>

<script>
export default {
  name: "SwitchOrgDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orgList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      radio: null,
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  methods: {
    handleOk() {
      if (!this.radio) {
        this.$message.error("请选择组织机构");
        return;
      }
      const orgData = this.orgList.find((item) => item.id == this.radio);
      this.$store.commit("user/SET_ISOLATION_ORG", orgData);
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-radio {
  width: 40%;
  margin-top: 12px;
}
</style>
