import Layout from "@/layout";

export default [
  {
    order: 5,
    path: "/tenant",
    component: Layout,
    meta: {
      title: "租户管理",
      subtitle: "租户",
      icon: "icon-zuzhi",
    },
    redirect: "/tenant/manage",
    children: [
      {
        path: "manage",
        name: "tenantManage",
        meta: {
          title: "租户管理",
          keepAlive: true,
          hiddenCrumb: true,
          permissions: ["utos:tenant:select"],
        },
        component: () => import("@/views/tenant/tenantManage/index.vue"),
      },
      {
        path: "detail",
        name: "tenantDetail",
        meta: {
          title: "租户详情",
          hidden: true,
          keepAlive: true,
          permissions: ["utos:tenant:select"],
        },
        component: () => import("@/views/tenant/tenantDetail/index.vue"),
      },
    ],
  },
];
