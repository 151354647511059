<template>
  <div ref="layoutHeader" class="header">
    <div class="header-left">
      <i
        class="iconfont icon-a-zhankaishouqi"
        :class="{ fold }"
        @click="handleFold"
      ></i>
      <breadcrumb class="breadcrumb-container" />
    </div>
    <div class="header-right">
      <div
        class="screen-entry"
        @click="jumpScreen"
        v-has="'monitor:comprehensive:screen'"
      ></div>
      <i
        class="iconfont icon-screen"
        :class="isFullscreen ? 'icon-suoxiao' : 'icon-fullscreen'"
        @click="handleFullscreen"
      />
      <!-- 告警铃铛 -->
      <alarm-setting></alarm-setting>
      <!-- 用户信息 -->
      <el-dropdown
        class="box-user"
        @command="handleCommand"
        @visible-change="handleVisibleChange"
      >
        <div class="user-info">
          <svg class="avatar icon" aria-hidden="true">
            <use xlink:href="#icon-touxiang-nan"></use>
          </svg>
          <div class="name">
            <p class="top">
              <span class="staff-name">{{ userInfo.staffName || "--" }}</span>
              <i
                v-if="!isEtbcTenant"
                :class="`el-icon-arrow-${showDropMenu ? 'up' : 'down'}`"
              ></i>
            </p>
            <p class="org-name" v-if="isolationOrgName">
              {{ isolationOrgName }}
            </p>
            <p class="tenant-name" v-if="selectTenantName">
              {{ selectTenantName }}
            </p>
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <template v-if="!isEtbcTenant">
            <el-dropdown-item
              :key="'changeOrg'"
              v-if="userInfo.needPeekIsolation"
              command="changeOrg"
              >切换组织机构</el-dropdown-item
            >
            <el-dropdown-item
              v-if="visibleSwitchTenant"
              :key="'changeTenant'"
              command="changeTenant"
              >切换租户</el-dropdown-item
            >
            <el-dropdown-item :key="'modifyPwd'" command="modifyPwd"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item :key="'logout'" command="logout"
              >退出登录</el-dropdown-item
            >
          </template>
        </el-dropdown-menu>
      </el-dropdown>

      <modify-pwd-dialog :show.sync="showModifyDialog" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "./Breadcrumb.vue";
import ModifyPwdDialog from "./ModifyPwdDialog.vue";
import AlarmSetting from "./AlarmSetting.vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import { clearLS } from "@/utils/storage";
import screenfull from "screenfull";
import { isEtbcTenant, jumpScreen } from "@/utils";

export default {
  name: "LayoutHeader",
  components: { Breadcrumb, ModifyPwdDialog, AlarmSetting },
  data() {
    return {
      isEtbcTenant,
      isFullscreen: false, // 是否全屏
      showDropMenu: false, // 是否显示下拉菜单
      showModifyDialog: false, // 是否显示修改密码弹框
    };
  },
  computed: {
    ...mapGetters({
      fold: "app/fold",
      isolationOrgName: "isolationOrgName",
      userInfo: "userInfo",
      websocket: "alarmPush/websocket",
    }),
    ...mapState({
      selectTenantName: (state) => state.user.selectTenantName,
    }),
    hasAlarmRecordsAuth() {
      return this.$has("monitor:run:alarm-record:select");
    },
    visibleSwitchTenant() {
      return (this.userInfo?.authRoleIds || []).includes("11");
    },
  },
  mounted() {
    if (screenfull.isEnabled) screenfull.on("change", this.screenChange);
    if (this.hasAlarmRecordsAuth && !this.websocket) {
      this.initWebSocket();
    }
    // 获取当前用户设置的告警时长
    this.getSoundDuration();
  },
  methods: {
    ...mapMutations({
      setFold: "app/SET_FLOD",
      changeShowSwitchOrgDialogGlobal:
        "user/CHANGE_SHOW_SWITCH_ORG_DIALOG_GLOBAL",
      updateSwitchTenantModalVisible: "user/UPDATE_SWITCH_TENANT_MODAL_VISIBLE",
    }),
    ...mapActions({
      initWebSocket: "alarmPush/initWebSocket",
      clearWebSocket: "alarmPush/clearWebSocket",
      getSoundDuration: "alarmPush/getSoundDuration",
    }),
    jumpScreen,
    // 展开/收起
    handleFold() {
      this.setFold(!this.fold);
    },
    handleFullscreen() {
      if (!screenfull.isEnabled) {
        this.$notify.error({
          title: "error",
          message: "开启全屏失败",
        });
      }
      screenfull.toggle();
      this.$emit("refresh");
    },
    screenChange() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    handleVisibleChange(flag) {
      this.showDropMenu = flag;
    },
    handleCommand(command) {
      switch (command) {
        case "logout":
          this.$confirm("你确定要退出系统吗？退出后需要重新登录系统。").then(
            () => this.handleLogout()
          );
          break;
        case "modifyPwd":
          this.showModifyDialog = true;
          break;
        case "changeOrg":
          this.changeShowSwitchOrgDialogGlobal(true);
          break;
        case "changeTenant":
          this.updateSwitchTenantModalVisible(true);
          break;
      }
    },
    // 退出登录
    handleLogout() {
      clearLS();
      location.reload();
    },
  },
  beforeDestroy() {
    if (screenfull.isEnabled) screenfull.off("change", this.screenChange);
    this.clearWebSocket();
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 24px;

  &-left {
    @include flex-center;
    .iconfont {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      cursor: pointer;
      transform: rotateY(0deg);
      position: relative;
      margin-right: 24px;
      &.fold {
        transform: rotateY(180deg);
      }
    }
  }

  &-right {
    height: 46px;
    padding: 10px 20px;
    background: #f0f1f5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    .iconfont {
      font-size: 32px;
      color: #c1c6db;
      cursor: pointer;
      &.icon-screen {
        font-size: 17px;
      }
    }
    .screen-entry {
      margin-right: 26px;
      width: 86px;
      height: 26px;
      background: url("~@/assets/images/home/screen-entry.png") no-repeat
        rgba(86, 124, 237, 0.5);
      background-size: 100%;
      border-radius: 25px;
      overflow: hidden;
      cursor: pointer;
    }

    .user-info {
      margin-left: 26px;
      @include flex-center;
      cursor: pointer;
      .avatar {
        width: 36px;
        height: 36px;
      }
      .name {
        margin-left: 12px;
        .staff-name {
          font-weight: 500;
          color: #333;
        }
        .org-name,
        .tenant-name {
          margin-top: 4px;
          font-size: 12px;
          color: #999;
          max-width: 160px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      i {
        margin-left: 6px;
      }
    }
  }
}
</style>
