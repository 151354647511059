var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"gc-table"},_vm.$listeners),[((!_vm.columns.length || !_vm.tableData.length) && _vm.totalHide)?_c('div',{staticClass:"gc-table-box"},[_c('gc-empty')],1):_vm._e(),((_vm.columns.length && _vm.tableData.length) || !_vm.totalHide)?_c('div',{staticClass:"table-show-box",class:{
      'is-show-page': _vm.showPage,
      'show-table-border': _vm.border,
      'had-not-got-data': !_vm.tableDataCopy.length || !_vm.tableDataCopy,
    }},[_c('el-table',{ref:"gcCloudTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableDataCopy,"border":_vm.border,"height":"100%","header-cell-style":{
        background: _vm.headerBackGroud,
        color: _vm.headerColor,
        'font-weight': 600,
      },"highlight-current-row":_vm.isHighlightCurrent,"stripe":_vm.isStripe,"show-header":_vm.showHeader,"fit":_vm.fit,"size":"medium"},on:{"selection-change":_vm.handleSelectionChange,"row-click":_vm.rowClick}},[_c('template',{staticClass:"noData",slot:"empty"},[_c('gc-empty',{attrs:{"page-type":_vm.pageType}})],1),(_vm.needType)?_c('el-table-column',{attrs:{"width":"55","type":_vm.needType,"align":_vm.headerTextAlign || 'left'}}):_vm._e(),_vm._l((_vm.columns),function(item){return [(!item.hide)?_c('el-table-column',{key:item.key,ref:item.key,refInFor:true,attrs:{"prop":item.key,"label":item.name,"fixed":item.fixed,"width":item.width,"resizable":!item.noResize,"min-width":item.minWidth || 150,"align":item.align || 'left',"render-header":item.isRH
              ? _vm.renderHeader
              : function (h, ref) {
                  var column = ref.column;

                  return h('span', column.label);
      },"show-overflow-tooltip":item.tooltip},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.render)?_c('customer-render',{attrs:{"row":scope.row,"render":item.render}}):_c('span',[_vm._v(_vm._s(_vm.judgeBlank(scope.row[item.key]) ? item.emptyCellText || _vm.emptyCellText + "" : scope.row[item.key]))])]}}],null,true)}):_vm._e()]})],2)],1):_vm._e(),(_vm.showPagination)?_c('div',{staticClass:"pagination-box"},[_c('gc-pagination',{ref:"pagination",attrs:{"total":_vm.total,"type":_vm.paginationType,"page-size":_vm.pageSize,"gas-total":_vm.gasTotal,"current-page":_vm.currentPageSelf,"page-params":_vm.pageParams},on:{"update:currentPage":function($event){_vm.currentPageSelf=$event},"update:current-page":function($event){_vm.currentPageSelf=$event},"current-page-change":_vm.currentPageChange,"current-size-change":_vm.currentSizeChange}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }