<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
      <span v-if="index === levelList.length - 1" class="text">
        {{ item.customCrumbTitle || item.meta.title }}
      </span>
      <a v-else @click.prevent="handleLink(item)" class="link">
        {{ item.customCrumbTitle || item.meta.title }}
      </a>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Breadcrumb",
  data() {
    return {
      levelList: [],
    };
  },
  watch: {
    $route: {
      handler() {
        this.$nextTick(() => {
          this.levelList = this.getBreadcrumb();
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      routes: "routes",
    }),
  },
  methods: {
    getBreadcrumb() {
      const customCrumb = this.$route?.meta?.customCrumb;
      if (customCrumb?.length) return customCrumb;
      return this.$route.matched.filter(
        (item) => item.meta && item.meta.title && !item.meta.hiddenCrumb
      );
    },
    handleLink(item) {
      if (item.customCrumbPath) {
        this.$router.push(item.customCrumbPath);
        return;
      }
      const currentRoute = this.routes.find((r) => r.path === item.path);
      this.$router.push(currentRoute?.children[0]?.fullPath || item?.path);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-breadcrumb__separator {
    margin: 0 4px;
  }
}
.el-breadcrumb__inner {
  font-weight: normal;
  .text {
    color: #595959;
  }
  .link {
    font-weight: normal;
    color: #999;
  }
}
</style>
