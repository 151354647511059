import clickBlur from "./clickBlur";
import has from "./has";

const directives = {
  clickBlur,
  has,
};

export default {
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key]);
    });
  },
};
