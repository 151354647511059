const defaultConfig = {
  pic: "device", //图片名
  title: "模块管理", //标题
  desc: "这是管理模块，展示列表", //说明
};

export default {
  default: defaultConfig,
  // 设备类型管理
  deviceTypeManage: {
    pic: "type-manage",
    title: "设备类型管理",
    desc: "对旗下涉及的所有设备类型进行统一规范化的管理维护。",
  },
  // 角色管理
  roleManage: {
    pic: "organize-role",
    title: "角色管理",
    desc: "可进行角色的管理, 包括角色的创建、删除、修改、查看功能。角色编辑包括角色权限的映射编辑功能。",
  },
  // 员工管理
  staffManage: {
    pic: "organize-staff",
    title: "员工管理",
    desc: "可进行员工的管理, 包括员工的创建、删除、修改、查看功能。",
  },
  customerManage: {
    pic: "organize-staff",
    title: "客户管理",
    desc: "通过用户号划分设备组，实现关联设备查询、设备分组管理及用气分析等。",
  },
  // 开户指令集配置
  openAccountSetting: {
    pic: "system-open-account",
    title: "开户指令集",
    desc: "可配置不同设备类型的多项开户指令集。",
  },
  regionManage: {
    pic: "region-manage",
    title: "区域管理",
    desc: "",
  },
  // 租户管理
  tenantManage: {
    pic: "tenant",
    title: "租户管理",
    desc: "可进行租户的管理, 包括租户的创建、编辑、禁用、查看和权限配置功能。",
  },
  secretKeyManage: {
    pic: "system-secret-key",
    title: "密钥管理",
    desc: "该模块的应用可增强系统的使用安全等级。",
  },
  // 批量指令管理
  batchDirectiveManage: {
    pic: "batch-directive",
    title: "批量指令管理",
    desc: "对每种设备类型下发过的批量指令进行监控与记录。",
  },
  // 告警设备管理
  alarmDevice: {
    pic: "alarm-device",
    title: "告警设备",
    desc: "可筛查出当前正在告警的设备。",
  },
  // 告警通知记录
  alarmNoticeRecords: {
    pic: "alarm-notice",
    title: "告警通知记录",
    desc: "对告警通知行为进行时间和事件记录。",
  },
  // 告警记录
  alarmRecords: {
    pic: "alarm-record",
    title: "告警记录",
    desc: "可筛查出当前正在告警的记录。",
  },
  // 异常记录
  abnormalRecords: {
    pic: "abnormal-record",
    title: "异常记录",
    desc: "可对异常记录进行筛选及处理。",
  },
  // 气量分析
  gasConsumptionAnalysis: {
    pic: "gas-analysis",
    title: "气量分析",
    desc: "可对单台计量设备、一户多台计量设备的气量监控及分析；可选择多台计量设备，如同一用户类型、同一用气性质的用户表具，进行气量对比",
  },

  // 对比分析
  contrastAnalysis: {
    pic: "abnormal-record",
    title: "对比分析",
    desc: "可对异常记录进行筛选及处理。",
  },
  pressureAnalysis: {
    pic: "gas-analysis",
    title: "压力分析",
    desc: "可对多个压力采集设备的气量监控及分析，更加直观的查看各压力点压力数据情况",
  },
  templateRoleManage: {
    pic: "template-role",
    title: "模板角色",
    desc: "可进行模板角色的管理, 包括模板角色的创建、编辑、查看等功能。",
  },
  directDetail: {
    pic: "batch-directive-detail",
    title: "批量指令详情",
    desc: "可查看该批指令相关属性参数，以及对应下发表具指令发送状态",
  },
  alarmMessage: {
    pic: "alarm-message",
    title: "告警消息策略",
    desc: "可通过告警消息策略将告警消息推送至接收人",
  },
  alarmParameter: {
    pic: "alarm-parameter",
    title: "告警参数配置",
    desc: "可根据实际需要对相关设备进行告警参数配置",
  },
  veeParamConfig: {
    pic: "alarm-parameter",
    title: "VEE参数配置",
    desc: "可根据实际需要对相关设备进行VEE相关参数配置",
  },
  accountManage: {
    pic: "account-manage",
    title: "台账管理",
    desc: "管理设备台账信息，目前可管理设备类型包含表具、报警器、自闭阀、压力采集器、综合采集器。",
  },
  waterAccountManage: {
    pic: "account-manage",
    title: "水务台账",
    desc: "",
  },
  vendorManage: {
    pic: "vendor-manage",
    title: "厂商管理",
    desc: "对旗下涉及的所有设备的生产厂商进行统一规范化的管理维护。",
  },
  // 组织机构管理（暂不实现）
  orgManage: {
    pic: "vendor-manage",
    title: "机构管理", //标题
    desc: "可进行组织机构的管理, 包括机构的创建、删除、修改、查看功能。",
  },
  batchImport: {
    pic: "batch-import",
    title: "批量导入",
    desc: "可根据设备类型提供动态模板下载，若导入失败则返回失败原因。",
  },
  metrologicalMonitor: {
    pic: "metrological-monitor",
    title: "计量监控",
    desc: "用于监控计量统计数据。",
  },
  waterMeterMonitor: {
    pic: "shuibiaojiankong",
    title: "水表监控",
    desc: "",
  },
  memberManage: {
    pic: "account-manage",
    title: "设备会员管理",
    desc: "对安全厨房的设备会员进行管理。",
  },
  timeoutDevice: {
    pic: "account-manage",
    title: "超使用年限设备管理",
    desc: "管理超使用年限设备信息，目前可管理设备类型包含表具、报警器、自闭阀、压力采集器、综合采集器。",
  },
  largeWaterAnalysis: {
    pic: "dabiaoshuiliang",
    title: "大表水量分析",
    desc: "",
  },
  smallWaterAnalysis: {
    pic: "xiaobiaoshuiliang",
    title: "小表水量分析",
    desc: "",
  },
};
