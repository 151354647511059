<template>
  <div class="header" :class="{ 'no-shadow': $slots.tabs }">
    <div class="header-info" :class="{ 'exist-tabs': $slots.tabs }">
      <div class="header-info-show">
        <!-- 图片 -->
        <div class="image-box">
          <!-- <img :src="headerConfig.pic" alt="" /> -->
          <img
            :src="
              require('@/assets/images/pic/' +
                headerConfig.pic +
                (headerConfig.suffix ? '.' + headerConfig.suffix : '.svg'))
            "
            alt=""
          />
        </div>
        <!-- 信息展示 -->
        <div class="info-show">
          <p class="info-title">
            <span>{{ headerConfig.title }}</span>
            <slot name="status"></slot>
          </p>
          <div class="info-desc">
            <p
              style="display: none"
              v-if="typeof headerConfig.desc == 'string'"
            >
              {{ headerConfig.desc }}
            </p>
            <div class="tag" v-else>
              <div
                class="tag-item"
                v-for="(item, index) in headerConfig.desc"
                :style="{ 'max-width': item.maxWidth || '30%' }"
                :key="index"
              >
                <i class="iconfont" :class="item.icon"></i>
                <Custom-Tooltip
                  class="span"
                  :refName="item.icon"
                  :content="item.text"
                ></Custom-Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 按钮操作区 -->
      <div class="btn-box">
        <slot name="ops"></slot>
      </div>
    </div>
    <!-- tab切换 -->
    <slot name="tabs"></slot>
  </div>
</template>

<script>
import config from "./headerConfig.js";
import { isString } from "@/utils/validate";
import CustomTooltip from "@/components/components/customTooltip";

export default {
  name: "gc-header",
  components: { CustomTooltip },
  props: {
    headerData: {
      require: true,
      type: [Object, String],
    },
  },
  computed: {
    headerConfig() {
      return isString(this.headerData)
        ? config[this.headerData]
        : this.headerData;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #f1f1f1;
  padding: 6px 18px;
  .header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-info-show {
      display: flex;
      align-items: center;
      padding-right: 20px;
      max-width: 70%;
      min-width: 60%;
      .image-box {
        margin-right: 14px;
        img {
          width: 58px;
          height: 58px;
        }
      }
      .info-show {
        flex: 1;
        width: 0;
        .info-title {
          font-size: 18px;
          color: #3f435e;
          font-weight: 500;
          .status {
            margin-left: 10px;
            display: inline-block;
            position: relative;
            width: 64px;
            height: 20px;
            font-size: 12px;
            border-radius: 4px;
            padding: 3px 0 5px 18px;
            box-sizing: border-box;
          }
          .status::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 3px;
          }
        }
        .info-desc {
          color: #999999;
          margin-top: 2px;
          p {
            line-height: 16px;
          }
        }
        .tag {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &-item {
            display: flex;
            max-width: 30%;
            line-height: 21px;
            i {
              color: #6b7399;
            }
            .span {
              width: 100%;
              color: #5c6073;
              margin-left: 4px;
            }
          }
          .tag-item + .tag-item {
            margin-left: 20px;
          }
        }
      }
    }
    .btn-box {
      flex-shrink: 0;
      display: flex;
      button + button {
        margin-left: 20px;
      }
    }
  }
  .header-info.exist-tabs {
    padding-bottom: 14px;
  }
}
.header.no-shadow {
  box-shadow: none;
}
</style>
