const defaultConfig = {
  icon: require("@/assets/images/pic/empty.svg"),
  text: "暂无数据",
  showBtn: false,
};

export default {
  default: defaultConfig,
  // 设备厂商管理
  deviceFactoryManage: {
    ...defaultConfig,
    icon: require("@/assets/images/pic/empty.svg"),
  },
  organizationManage: {
    ...defaultConfig,
    icon: require("@/assets/images/pic/empty.svg"),
  },
  staffManage: {
    ...defaultConfig,
    text: "暂无员工，快去新增吧~",
  },
  staffManageForDepartment: {
    ...defaultConfig,
    text: "需有部门才能添加员工",
    btnText: "新增部门",
    showBtn: true,
  },
  directiveSend: {
    ...defaultConfig,
    text: "请选择左侧指令",
  },
  roleManage: {
    ...defaultConfig,
    text: "暂无角色，快去新增吧～",
  },
  templateRoleManage: {
    ...defaultConfig,
    text: "暂无模板角色，快去新增吧～",
  },
  openAccountSettings: {
    ...defaultConfig,
    text: "暂无设备接入",
  },
  openAccountSettingsConfig: {
    ...defaultConfig,
    text: "该设备类型没有可配置的开户指令",
  },
  channelInfo: {
    ...defaultConfig,
    text: "暂无设备",
  },
  regionManage: {
    ...defaultConfig,
    text: "暂无区域数据",
    showBtn: true,
    btnText: "初始化根节点",
  },
  regionManage2: {
    ...defaultConfig,
    text: "暂无区域数据，请开通初始化根节点权限",
  },
};
