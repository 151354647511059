import vue from "vue";
import Toast from "./index.vue";
import store from "@/store";

const ToastConstructor = vue.extend(Toast);

let instance = null;

const toast = (options) => {
  toast.clear();
  if (typeof options === "string") {
    options = {
      message: options,
    };
  }
  instance = new ToastConstructor({
    el: document.createElement("div"),
    data() {
      return options;
    },
  });
  document.body.appendChild(instance.$el);
  store.commit("settings/SET_SHOW_TOAST", true);
};

toast.clear = () => {
  const { showToast } = store.state.settings;
  if (showToast) {
    document.body.removeChild(instance.$el);
  }
  instance = null;
  store.commit("settings/SET_SHOW_TOAST", false);
};

export default toast;
