import Layout from "@/layout";

export default [
  {
    order: 4,
    path: "/alarm",
    component: Layout,
    meta: {
      title: "告警配置",
      subtitle: "告警",
      icon: "icon-gaojing",
    },
    redirect: "/alarm/param-config",
    children: [
      {
        path: "param-config",
        name: "alarmParamConfig",
        meta: {
          title: "告警参数配置",
          keepAlive: true,
          permissions: ["monitor:alarm-config:config"],
        },
        component: () => import("@/views/alarmConfig/param/index.vue"),
      },
      {
        path: "vee-config",
        name: "veeParamConfig",
        meta: {
          title: "VEE参数配置",
          keepAlive: true,
          permissions: ["monitor:vee-config:config"],
        },
        component: () => import("@/views/alarmConfig/vee/index.vue"),
      },
      {
        path: "message",
        name: "alarmMessage",
        meta: {
          title: "告警消息策略",
          keepAlive: true,
          permissions: ["monitor:alarm-config:push"],
        },
        component: () => import("@/views/alarmConfig/message/index.vue"),
      },
    ],
  },
];
