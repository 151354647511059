import Layout from "@/layout";
import { deviceDetailPermissions } from "@/config/const";

export default [
  {
    order: 3,
    path: "/run",
    component: Layout,
    meta: {
      title: "运行管理",
      subtitle: "运行",
      icon: "icon-yunhang",
    },
    redirect: "/run/send-direct",
    children: [
      {
        path: "run-board",
        name: "runBoard",
        meta: {
          title: "运行看板",
          keepAlive: false,
          permissions: ["monitor:run:dashboard"],
        },
        component: () => import("@/views/runManage/runBoard/index.vue"),
      },
      {
        path: "metrological-monitor",
        name: "metrologicalMonitor",
        meta: {
          title: "计量监控",
          keepAlive: true,
          permissions: ["monitor:run:measurement-data"],
          tenantType: "0",
        },
        component: () =>
          import("@/views/runManage/metrologicalMonitor/index.vue"),
      },
      {
        path: "water-meter-monitor",
        name: "waterMeterMonitor",
        meta: {
          title: "水表监控",
          keepAlive: true,
          permissions: ["monitor:report:watermeasurementdata:preview"],
          tenantType: "1",
        },
        component: () =>
          import("@/views/runManage/waterMeterMonitor/index.vue"),
      },
      {
        path: "batch-directive",
        name: "batchDirectiveManage",
        meta: {
          title: "批量指令管理",
          keepAlive: true,
          permissions: [
            "monitor:run:batch-command:select",
            "monitor:run:batch-command:insert",
          ],
        },
        component: () =>
          import("@/views/runManage/batchDirectiveManage/index.vue"),
      },
      {
        path: "direct-detail",
        name: "directDetail",
        meta: {
          title: "批量指令详情",
          keepAlive: true,
          hidden: true,
          permissions: ["monitor:run:batch-command:select"],
          customCrumb: [
            { customCrumbTitle: "运行管理", path: "/run" },
            {
              customCrumbTitle: "批量指令管理",
              customCrumbPath: "/run/batch-directive",
            },
            { customCrumbTitle: "批量指令详情" },
          ],
        },
        component: () => import("@/views/runManage/directiveDetail/index.vue"),
      },
      {
        path: "abnormal-records",
        name: "abnormalRecords",
        meta: {
          title: "异常记录",
          keepAlive: true,
          permissions: ["monitor:run:exception:select"],
        },
        component: () => import("@/views/runManage/abnormalRecords/index.vue"),
      },
      {
        path: "alarm-device",
        name: "alarmDevice",
        meta: {
          title: "告警设备",
          keepAlive: true,
          permissions: ["monitor:run:alarm-device:select"],
        },
        component: () => import("@/views/runManage/alarmDevice/index.vue"),
      },
      {
        path: "alarm-records",
        name: "alarmRecords",
        meta: {
          title: "告警记录",
          keepAlive: true,
          permissions: ["monitor:run:alarm-record:select"],
        },
        component: () => import("@/views/runManage/alarmRecords/index.vue"),
      },
      {
        path: "alarm-notice-records",
        name: "alarmNoticeRecords",
        meta: {
          title: "告警通知记录",
          keepAlive: true,
          permissions: ["monitor:run:alarm-record:notify:select"],
        },
        component: () =>
          import("@/views/runManage/alarmNoticeRecords/index.vue"),
      },
      {
        path: "gas-consumption-analysis",
        name: "gasConsumptionAnalysis",
        meta: {
          title: "气量分析",
          keepAlive: true,
          permissions: ["monitor:run:gas-analyze"],
        },
        component: () =>
          import("@/views/runManage/gasConsumptionAnalysis/index.vue"),
      },
      {
        path: "single-gas-consumption-analysis",
        name: "singleGasConsumptionAnalysis",
        meta: {
          title: "单用户气量分析",
          keepAlive: false,
          hidden: true,
          permissions: ["monitor:run:gas-analyze"],
          customCrumb: [
            { customCrumbTitle: "运行管理", path: "/run" },
            {
              customCrumbTitle: "气量分析",
              customCrumbPath: "/run/gas-consumption-analysis",
            },
            { customCrumbTitle: "单用户气量分析" },
          ],
        },
        component: () =>
          import(
            "@/views/runManage/gasConsumptionAnalysis/singleGasConsumptionAnalysis/index.vue"
          ),
      },
      {
        path: "contrast-analysis",
        name: "contrastAnalysis",
        meta: {
          title: "对比分析",
          keepAlive: false,
          hidden: true,
          permissions: ["monitor:run:gas-analyze"],
        },
        component: () =>
          import(
            "@/views/runManage/gasConsumptionAnalysis/contrastAnalysis/index.vue"
          ),
      },
      {
        path: "pressure-analysis",
        name: "pressureAnalysis",
        meta: {
          title: "压力分析",
          keepAlive: true,
          permissions: ["monitor:run:pressure"],
        },
        component: () => import("@/views/runManage/pressureAnalysis/index.vue"),
      },
      {
        path: "timeout-device",
        name: "timeoutDevice",
        meta: {
          title: "超使用年限设备",
          keepAlive: true,
          permissions: deviceDetailPermissions,
        },
        component: () => import("@/views/runManage/timeoutDevice/index.vue"),
      },
      {
        path: "timeout-device-detail",
        name: "timeoutDeviceDetail",
        meta: {
          title: "设备详情",
          keepAlive: false,
          hidden: true,
          customCrumb: [
            { customCrumbTitle: "运行管理", path: "/run" },
            {
              customCrumbTitle: "超使用年限设备管理",
              customCrumbPath: "/run/timeout-device",
            },
            { customCrumbTitle: "设备详情" },
          ],
        },
        component: () => import("@/views/deviceManage/detail/index.vue"),
      },
    ],
  },
];
