export default {
  state: {
    needRefresh: false,
    dataTemplate: {}, // 批量导入-已下载的数据模板信息
    deviceView: {}, // 设备视图信息
    excelColumns: [], // 已下载表格表头信息
    deviceTotalAttrs: [], // 当前设备需要上传的所有属性
    successData: [], // 本次可导入设备数据
    failData: [], // 本次不可导入设备数据
    importFailData: [], // 批量导入失败的数据
    relationDeviceChange: false, //关联设备变化
    configRuleUpdate: false, //配置信息-设备规则配置更新
  },
  getters: {
    dataTemplate: (state) => state.dataTemplate,
    deviceView: (state) => state.deviceView,
    excelColumns: (state) => state.excelColumns,
    deviceTotalAttrs: (state) => state.deviceTotalAttrs,
    successData: (state) => state.successData,
    failData: (state) => state.failData,
    importFailData: (state) => state.importFailData,
    relationDeviceChange: (state) => state.relationDeviceChange,
  },
  mutations: {
    CHNAGE_STATUS(state, status) {
      state.needRefresh = status;
    },
    SET_DATA_TEMPLATE(state, payload) {
      state.dataTemplate = payload;
    },
    SET_DEVICE_VIEW(state, payload) {
      state.deviceView = payload;
    },
    SET_EXCEL_COLUMNS(state, payload) {
      state.excelColumns = payload;
    },
    SET_DEVICE_TOTAL_ATTRS(state, payload) {
      state.deviceTotalAttrs = payload;
    },
    SET_SUCCESS_DATA(state, payload) {
      state.successData = payload;
    },
    SET_FAIL_DATA(state, payload) {
      state.failData = payload;
    },
    SET_IMPORT_FAIL_DATA(state, payload) {
      state.importFailData = payload;
    },
    CLEAR_BATCH_IMPORT_DATA(state) {
      state.dataTemplate = {};
      state.deviceView = {};
      state.excelColumns = [];
      state.deviceTotalAttrs = [];
      state.successData = [];
      state.failData = [];
      state.importFailData = [];
    },
    SET_RELATION_DEVICE_CHANGE(state, payload) {
      state.relationDeviceChange = payload;
    },
    SET_CONFIG_RULE_UPDATE(state, payload) {
      state.configRuleUpdate = payload;
    },
  },
  actions: {
    changeStatus({ commit }, status) {
      commit("CHNAGE_STATUS", status);
    },
  },
};
