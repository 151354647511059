<template>
  <!-- :style="{ maxWidth: data.row == 1 ? '' : (data.row || 3) * 250 + 'px', }" -->
  <div class="gc-group-detail">
    <div class="z-page-group">
      <div class="z-group-title" v-if="data.title">
        <div class="_title">{{ data.title }}</div>
        <slot name="head"></slot>
      </div>
      <div class="z-group-details">
        <group-detail-item
          :style="`width: ${
            (100 / (data.row || 3)) * Math.min(item.col || 1, data.row || 3)
          }%`"
          v-for="item in data.list || []"
          :key="item.key"
          :name="item.label"
          :labelWidth="data.width"
        >
          <div v-if="$slots[item.slot]">
            <slot :name="item.slot"></slot>
          </div>
          <Custom-Tooltip
            :refName="item.key"
            :content="item.value"
            v-bind="$attrs"
          ></Custom-Tooltip>
        </group-detail-item>
      </div>
    </div>
  </div>
</template>

<script>
import GroupDetailItem from "./groupDetailItem";
import CustomTooltip from "@/components/components/customTooltip";
export default {
  name: "GcGroupDetail",
  components: {
    GroupDetailItem,
    CustomTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: "",
          row: 3,
          list: [],
        };
      },
    },
    vauleStyle: Object,
  },
};
</script>

<style lang="scss" scoped>
.z-page-group {
  padding: 30px 0 30px 0;
  .z-group-title {
    ._title {
      font-size: 16px;
      color: #333;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
  .z-group-details {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
