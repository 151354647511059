import { MessageBox } from "element-ui";

const confirm = (msg, title = "提示", options = { showClose: false }) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(msg, title, {
      customClass: `gc-message-box ${!title ? "no-title" : ""}`,
      confirmButtonText: "确 定",
      cancelButtonText: "取 消",
      type: "warning",
      center: true,
      ...options,
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export default confirm;
