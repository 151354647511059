import DataList from "@/config/dataList";
import { getLS, setLS } from "@/utils/storage";
import { apiDataList } from "@/api/login";
import {
  apiDeviceTypeList,
  apiGetFirstCategory,
  apiAllDeviceTypeList,
} from "@/api/type";
import { isBlank } from "@/utils/validate";

export default {
  state: {
    dataList: getLS("dataList") || DataList, // 数据字典
    deviceTypeList: [], // 已授予的设备类型列表
    allDeviceTypeList: [], // 已授予和已撤销的设备类型列表
    firstCategory: [], // 设备大类
    needUpdateAlarmRecords: false, // 是否需要更新告警记录列表
  },
  getters: {
    needUpdateAlarmRecords: (state) => state.needUpdateAlarmRecords,
  },
  mutations: {
    SET_DATA_LIST(state, payload) {
      setLS("dataList", payload);
      state.dataList = payload;
    },
    SET_DEVICE_TYPE_LIST(state, payload) {
      state.deviceTypeList = payload;
    },
    SET_ALL_DEVICE_TYPE_LIST(state, payload) {
      state.allDeviceTypeList = payload;
    },
    SET_FIRST_CATEGORY(state, payload) {
      state.firstCategory = payload;
    },
    SET_UPDATE_ALARM_RECORDS(state, payload) {
      state.needUpdateAlarmRecords = payload;
    },
  },
  actions: {
    getDataList({ commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        apiDataList()
          .then(({ records = [] }) => {
            const dataMap = {};
            for (let i = 0, l = records.length; i < l; i++) {
              const item = records[i];
              // 当前用户非管理员，则根据用户的业务类型过滤数据字典
              if (
                !isBlank(rootGetters.userInfo.tenantType) &&
                !isBlank(item.tenantType)
              ) {
                if (
                  item.tenantType
                    .split(",")
                    .includes(rootGetters.userInfo.tenantType)
                ) {
                  if (dataMap[item.sortCode]) {
                    dataMap[item.sortCode].push(item);
                  } else {
                    dataMap[item.sortCode] = [item];
                  }
                }
              } else {
                if (dataMap[item.sortCode]) {
                  dataMap[item.sortCode].push(item);
                } else {
                  dataMap[item.sortCode] = [item];
                }
              }
            }
            commit("SET_DATA_LIST", dataMap);
            resolve(records); // 返回源数据
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDeviceTypeList({ commit, rootGetters }, params = {}) {
      return new Promise((resolve, reject) => {
        apiDeviceTypeList({
          tenantId: rootGetters.userInfo?.tenantId,
          ...params,
        })
          .then((records = []) => {
            const data = records?.map((item) => {
              return {
                ...item,
                value: item.deviceTypeId,
                label: item.deviceTypeName,
              };
            });
            commit("SET_DEVICE_TYPE_LIST", data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取已授予和已撤销的设备类型列表
    getAllDeviceTypeList({ commit, rootGetters }, params = {}) {
      return new Promise((resolve, reject) => {
        apiAllDeviceTypeList({
          tenantId: rootGetters.userInfo?.tenantId,
          ...params,
        })
          .then(({ records = [] }) => {
            const data = records?.map((item) => {
              return {
                ...item,
                value: item.deviceTypeId,
                label: item.deviceTypeName,
              };
            });
            commit("SET_ALL_DEVICE_TYPE_LIST", data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取设备大类列表
    getFirstCategory({ state, commit }) {
      return new Promise((resolve) => {
        if (state.firstCategory?.length) {
          resolve(state.firstCategory);
        } else {
          apiGetFirstCategory().then(({ list = [] }) => {
            commit("SET_FIRST_CATEGORY", list);
            resolve(list);
          });
        }
      });
    },
  },
};
