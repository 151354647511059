import { Message } from "element-ui";

let instance = null;

function handleOptions(options) {
  if (typeof options === "string") {
    options = {
      message: options,
    };
  }
  options.offset = options.offset || 100;
  return options;
}

const message = (options) => {
  if (instance) instance.close();
  options = handleOptions(options);
  instance = Message(options);
};

["success", "warning", "info", "error"].forEach((type) => {
  message[type] = (options) => {
    options = handleOptions(options);
    options.type = type;
    return message(options);
  };
});

export default message;
