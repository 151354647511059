import * as Sentry from "@sentry/vue";
import axios from "axios";

const ENV = process.env.NODE_ENV;

export async function initSentry(Vue) {
  const { data } = await axios.get("/static/config.json");
  const {
    VUE_APP_SENTRY_SWITCH,
    VUE_APP_SENTRY_LOG_ERROR,
    VUE_APP_SENTRY_DSN,
  } = data;
  if (!VUE_APP_SENTRY_SWITCH) return;
  Sentry.init({
    Vue,
    dsn: VUE_APP_SENTRY_DSN,
    environment: ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    logErrors: VUE_APP_SENTRY_LOG_ERROR,
    tracesSampleRate: 1.0,
    release: "1.0.0",
  });
}
