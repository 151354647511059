<template>
  <!-- 分页器组件 -->
  <div
    class="gc-cloud-pagination"
    :style="{
      'justify-content': type === 'normal' ? 'space-between' : 'flex-end',
    }"
  >
    <span class="total" v-if="total"
      >共{{ " " + total + " " }}条<span v-if="gasTotal"
        >｜ 总用气量：{{ gasTotal }}m³</span
      ></span
    >
    <div v-if="type === 'normal'" class="gc-cloud-pagination-right">
      <span
        >{{ currentPageOut ? currentPageOut : currentPageSelf }}/{{
          Math.ceil(total / pageSize)
        }}页</span
      >
      <el-pagination
        background
        :current-page="currentPageOut ? currentPageOut : currentPageSelf"
        :page-size="pageSize"
        :total="total"
        :layout="layout"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div v-else class="only-up-next">
      <span>当前第{{ noPageParams.currentPage }}页</span>
      <div
        :class="['button', { 'not-allow': !noPageParams.hasUp }]"
        @click="clickUp"
      >
        <i class="el-icon-arrow-left"></i>
      </div>
      <div
        :class="['button', { 'not-allow': !noPageParams.hasNext }]"
        @click="clickNext"
      >
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getPrevIndex } from "@/utils";

export default {
  name: "GcPagination",
  props: {
    // 分页器类型
    type: {
      type: String,
      default: "normal", //normal special(仅上一页、下一页)
    },
    pageParams: {
      type: Object,
      default: () => {},
    },
    //  页宽
    pageSize: {
      type: Number,
      default: 10,
    },
    //  总条数
    total: {
      type: Number,
      default: 0,
    },
    //  当前页码
    currentPage: {
      type: [Number, String],
      default: 0,
    },
    // 是否展示回到首页和去到末页
    showTopAndEnd: {
      type: Boolean,
      default: true,
    },
    isTotal: {
      type: Boolean,
      default: true,
    },
    // 页宽集合
    sizeOptinos: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 100],
    },
    // 用气报表总用气量
    gasTotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPageSelf: 1,
      idOffsetArr: [""],
      noPageParams: {
        currentPage: null,
        hasUp: null,
        hasNext: null,
        nextIdOffset: null,
      },
    };
  },
  computed: {
    layout() {
      return "prev, next, jumper";
    },
    currentPageOut: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("update:currentPage", val);
      },
    },
  },
  watch: {
    pageParams: {
      deep: true,
      immediate: true,
      handler(val) {
        let { hasNext, idOffset: nextIdOffset, currentIdOffset } = val;
        let index = this.idOffsetArr.indexOf(currentIdOffset);
        if (index > -1) {
          this.idOffsetArr = this.idOffsetArr.splice(0, index + 1);
        } else {
          this.idOffsetArr.push(currentIdOffset);
        }
        let currentPage = this.idOffsetArr.length;
        let prevIndex = getPrevIndex(this.idOffsetArr, currentIdOffset);
        let prevIdOffset = this.idOffsetArr[prevIndex];
        this.noPageParams = {
          currentPage,
          hasUp: prevIndex > -1,
          hasNext,
          nextIdOffset,
          prevIdOffset,
        };
      },
    },
  },
  methods: {
    // 支持跳页的分页器方法
    handleCurrentChange(curPage) {
      this.currentPageSelf = curPage;
      this.$emit("current-page-change", curPage);
    },
    handleCommand(command) {
      this.$emit("current-size-change", command);
    },
    // 重置分页器
    resetPage() {
      this.currentPageSelf = 1;
    },
    // 不支持分页的分页器方法
    clickUp() {
      if (!this.noPageParams.hasUp) return;
      this.$emit("current-page-change", this.noPageParams.prevIdOffset);
    },
    clickNext() {
      if (!this.noPageParams.hasNext) return;
      this.$emit("current-page-change", this.noPageParams.nextIdOffset);
    },
  },
};
</script>
<style lang="scss" scoped>
.gc-cloud-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000000;
  font-size: 12px;
  padding: 10px 0;
  .total {
    font-weight: normal;
    color: #606266;
    font-size: 13px;
  }
  &-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }
  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    .select {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin: 0 6px;
      padding: 0 12px;
    }
  }
  .gc-cloud-pagination-right {
    .el-pagination.is-background {
      padding: 2px 0 2px 5px;
      ::v-deep .el-pagination__jump {
        margin-left: 20px;
      }
    }
  }
  .only-up-next {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 30px;
      color: #303030;
      font-size: 13px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin-left: 10px;
      cursor: pointer;
    }
    .not-allow {
      cursor: not-allowed;
      color: #c0c4cc;
    }
  }
  ::v-deep .el-pagination.is-background {
    & .el-pager li {
      min-width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
      // &:not(.disabled).active {
      //   border-color: #f65d3a;
      //   background-color: #f65d3a;
      // }
    }
    & .btn-prev,
    & .btn-next {
      width: 32px;
      height: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }
  }
  ::v-deep .el-dropdown-link {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
  }
}
</style>
