<template>
  <div v-if="!item.hidden" class="sidebar-item">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren)
      "
    >
      <router-link
        class="item-link"
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild.path)"
      >
        <el-menu-item :index="resolvePath(onlyOneChild.path)">
          <i
            class="iconfont"
            :class="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
          ></i>
          <span class="title">
            {{ showTitle(onlyOneChild) }}
          </span>
        </el-menu-item>
      </router-link>
    </template>

    <el-submenu
      v-else
      :popper-class="`sidebar-submenu ${
        activeMenu.indexOf(item.path) > -1 ? 'is-active' : ''
      }`"
      :index="resolvePath(item.path)"
    >
      <template slot="title">
        <i class="iconfont" :class="item.meta.icon"></i>
        <span class="title">{{ showTitle(item) }}</span>
      </template>
      <submenu
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from "path";
import { mapGetters } from "vuex";

export default {
  name: "Submenu",
  props: {
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: "",
    },
    activeMenu: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  computed: {
    ...mapGetters({
      fold: "app/fold",
    }),
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) return false;
        this.onlyOneChild = item;
        return true;
      });
      if (showingChildren.length === 1) {
        return true;
      }
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }
      return false;
    },
    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath);
    },
    showTitle(item) {
      return this.fold
        ? this.item.meta?.subtitle || item.meta?.title
        : item.meta?.title;
    },
  },
};
</script>
