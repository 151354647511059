import Layout from "@/layout";

export default [
  {
    order: 9,
    path: "/system",
    component: Layout,
    meta: {
      title: "系统管理",
      subtitle: "系统",
      icon: "icon-zuzhi",
    },
    redirect: "/system/role-template-manage",
    children: [
      {
        path: "role-template-manage",
        name: "roleTemplateManage",
        meta: {
          icon: "icon-mobanjiaose",
          title: "模板角色维护",
          keepAlive: true,
          permissions: ["utos:template:role:select"],
        },
        component: () => import("@/views/organizeManage/roleManage/index.vue"),
      },
    ],
  },
];
