// 表单规则校验相关方法

import { isBlank } from "./validate";
import { isComplexPass } from "./index";

/**
 * string 类型必填项校验
 * @param { string } message 校验提示说明文字
 * @return { object }
 */
export function ruleRequired(message, trigger = "") {
  return {
    required: true,
    message: message,
    trigger,
    validator: (rule, value, callback) => {
      if (isBlank(value)) {
        return callback(new Error(message));
      }
      callback();
    },
  };
}

/**
 * 最大长度限制校验
 * @param { number } max
 * @param { String } label
 * @return { object }
 */
export function ruleMaxLength(max, label = "输入字符") {
  return {
    max,
    message: `${label}不能超过${max}位`,
    trigger: "",
  };
}

/**
 * 最小长度限制校验
 * @param { number } min
 * @param { String } label
 * @return { object }
 */
export function ruleMinLength(min, label = "请输入") {
  return {
    min,
    message: `${label}至少${min}位字符`,
    trigger: "",
  };
}

// 手机号格式校验
export const RULE_PHONE = {
  pattern: /^1[3456789]\d{9}$/,
  message: "输入手机号格式不对，11位且13-19开头",
  trigger: "",
};

export const RULE_INT_ENGLISH = {
  pattern: /^[a-z0-9]+$/i,
  message: "只能输入数字和英文字母",
  trigger: "",
};

export const RULE_POSITIVEINTEGERONLY = {
  pattern: /^[1-9]\d*$/,
  message: "必须输入正整数",
  trigger: "",
};

export const RULE_INTEGERONLY = {
  pattern: /^[0-9]\d*$/,
  message: "必须输入整数",
  trigger: "",
};

export const RULE_INCORRECTIDCARD = {
  pattern: /(^\d{15}$)|(^\d{17}(\d|X|x)$)/,
  message: "身份证格式错误",
  trigger: "",
};

export const RULE_INCORRECTEMAIL = {
  pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
  message: "邮箱格式错误",
  trigger: "",
};

export const RULE_INCORRECTIP = {
  required: true,
  pattern:
    /^(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)$/,
  message: "IP格式错误",
  trigger: "",
};

export const RULE_INVALIDINPUT = {
  pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
  message: "必须为字母开头，由字母、数字、下划线组成",
  trigger: "",
};

export const RULE_INVALIDSMALLINPUT = {
  pattern: /^[a-z][a-z0-9_]*$/,
  message: "必须为小写字母开头，由小写字母、数字、下划线组成",
  trigger: "",
};

export const RULE_PASSWORD = {
  pattern: /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@+._#$%^&*? ]).*$/,
  message:
    "需至少包含以下4种字符：大、小写字母、数字、特殊字符, 长度大于等于8位",
  trigger: "",
};

export const RULE_CORRECTINPUT = {
  pattern: /^[a-zA-Z0-9_]*$/,
  message: "仅允许输入字母、数字、下划线",
  trigger: "",
};

export const RULE_CORRECTLOWERLETTER = {
  pattern: /^[a-z0-9_]*$/,
  message: "仅允许输入小写字母、数字、下划线",
  trigger: "",
};

export const RULE_LETTERFIRST = {
  pattern: /^[a-zA-Z][a-z0-9_]*$/,
  message: "仅允许输入小写字母、数字、下划线, 且必须由字母开头",
  trigger: "",
};

export const RULE_SPECIALPARAMS = {
  pattern: /^((?![\\、@#$%^&*]).)*$/,
  message: "不能包含\\、@#$%^&*",
  trigger: "",
};

export const RULE_CHINESE = {
  required: true,
  pattern: /[\u4e00-\u9fa5]/,
  message: "必须输入中文",
  trigger: "",
};

export const RULE_ENGLISH = {
  required: true,
  pattern: /^[^\u4e00-\u9fa5]+$/,
  message: "不能输入中文",
  trigger: "",
};

export const RULE_CASE_NUM = {
  pattern: /^[A-Za-z0-9]+$/,
  message: "只能输入大小写字母和数字",
  trigger: "",
};

// 告警播放时长校验规则
export const RULE_SOUND_DURATION = {
  validator: (rule, value, callback) => {
    if (isBlank(value)) return callback();
    const reg = /^(0|[1-9][0-9]*)(\.[0-9])?$/s;
    if (!reg.test(value) || value < 0 || value > 525600) {
      return callback(new Error("设置范围为0-525600，仅限一位小数"));
    }
    callback();
  },
  trigger: ["change", "blur"],
};

/**
 * 等保密码复杂度校验
 * @return { object }
 */
export function ruleComplexPassValidate() {
  const passvalidate = (rule, value, callback) => {
    if (!isComplexPass(value)) {
      callback(new Error("至少两种字符组合(数字/字母/特殊符号)!"));
    } else {
      callback();
    }
  };
  return { validator: passvalidate, trigger: "" };
}

// 整数
export const ruleOnlyInteger = {
  pattern: /^[0-9]\d*$/,
  message: "必须输入整数",
  trigger: "",
};

// 限制输入最小值
export function ruleLt(val = 0) {
  return {
    trigger: "",
    validator: (_rule, value, callback) => {
      if (!isBlank(value) && Number(value) <= val) {
        return callback(new Error(`请输入大于${val}的数字`));
      }
      callback();
    },
  };
}
