import Layout from "@/layout";

export default [
  {
    order: 5,
    path: "/organize",
    component: Layout,
    meta: {
      title: "组织管理",
      subtitle: "组织",
      icon: "icon-zuzhi",
    },
    redirect: "/organize/tenant",
    children: [
      // {
      //   path: "institution-manage",
      //   name: "institutionManage",
      //   meta: {
      //     title: "机构管理",
      //     keepAlive: true,
      //   },
      //   component: () =>
      //     import("@/views/organizeManage/institutionManage/index.vue"),
      // },
      {
        path: "staff-manage",
        name: "staffManage",
        meta: {
          title: "员工管理",
          keepAlive: true,
          permissions: ["utos:tosm:staff:org:select"],
        },
        component: () => import("@/views/organizeManage/staffManage/index.vue"),
      },
      {
        path: "role-manage",
        name: "roleManage",
        meta: {
          title: "角色管理",
          keepAlive: true,
          permissions: ["utos:tosm:role:select"],
        },
        component: () => import("@/views/organizeManage/roleManage/index.vue"),
      },
    ],
  },
];
