const files = require.context("./", true, /\.vue$/);

const components = files.keys().reduce((components, path) => {
  const component = files(path).default;
  components[component.name] = component;
  return components;
}, {});

export default {
  install(Vue) {
    Object.keys(components).forEach((key) => {
      Vue.component(key, components[key]);
    });
  },
};
