<template>
  <div class="mask">
    <div class="toast">
      <svg class="icon" aria-hidden="true">
        <use :xlink:href="`#${icon}`"></use>
      </svg>
      <slot>{{ message }}</slot>
      <i v-if="showClose" class="iconfont icon-close" @click="destroy"></i>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Toast",
  data() {
    return {
      icon: "icon-dukazhong",
      message: "",
      showClose: false,
    };
  },
  methods: {
    destroy() {
      if (this.$el) {
        store.commit("settings/SET_SHOW_TOAST", false);
        this.$el.parentNode.removeChild(this.$el);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.2);
  @include flex-center;
  .toast {
    @include flex-center;
    min-width: 300px;
    height: 100px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-weight: 500;
    color: #333;
    position: relative;

    .icon {
      width: 22px;
      min-height: 17px;
      margin-right: 12px;
    }

    .icon-close {
      position: absolute;
      top: 12px;
      right: 12px;
      transform: scale(0.8);
      font-size: 13px;
      color: #ccc;
      cursor: pointer;
    }
  }
}
</style>
