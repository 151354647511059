import router from "@/router";
import { Notification } from "element-ui";
import alarmIcon from "@/assets/images/icon/alarm-push.png";
import alarmScreenIcon from "@/assets/images/icon/alarm-push-screen.png";
import mp3 from "@/assets/audio/620.mp3";
import { isProd } from "@/config";
import { apiGetTenantConfigs } from "@/api/tenant.js";
import { setInterval } from "core-js";
let audio = null;

export default {
  state: {
    websocket: null,
    timer: null,
    alarmCount: 0, // 告警数量
    reconnectTimes: 0, // websocket断开重连次数
    updateApiTimes: 0,
    isLock: false,
    notifyList: [], // 当前弹屏列表
    lockReconnect: false,
    soundDuration: 10, // 告警时长
    soundTimer: null, // 循环播放告警定时器
    audioDuration: 2000, // 音频播放间隔,默认2000毫秒
    boardUpdate: false, //运行看板是否更新
  },
  getters: {
    websocket: (state) => state.websocket,
    alarmCount: (state) => state.alarmCount,
    updateApiTimes: (state) => state.updateApiTimes,
    boardUpdate: (state) => state.boardUpdate,
    soundDuration: (state) => state.soundDuration,
  },
  mutations: {
    SET_ALARM_COUNT(state, payload) {
      state.alarmCount = payload;
    },
    SET_SOUND_DURATION(state, payload) {
      state.soundDuration = Number(payload);
    },
    SET_BOARD_UPDATE(state, payload) {
      state.boardUpdate = payload;
    },
  },
  actions: {
    // 模拟告警推送
    mockAlarmPush({ state, dispatch }) {
      const data = {
        alarmName: "测试声音告警",
        deviceName: "金卡综合采集器-温压专用",
        alarmTime: "2022-12-05 16:36:36",
        addressName: "北京市北京市朝阳区603",
        soundAlarm: true,
      };
      setTimeout(() => {
        dispatch("showAlarmNotify", data);
      }, 1000);
      let times = 0;
      let timer = setInterval(() => {
        times += 1;
        if (times > 10) {
          clearInterval(timer);
          return;
        }
        if (state.notifyList.length > 4) {
          const instance = state.notifyList.shift();
          instance.close();
        }
        dispatch("showAlarmNotify", data);
      }, 1500);
    },
    // 获取告警时长配置
    getSoundDuration({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        apiGetTenantConfigs({
          tenantId: rootGetters.userInfo?.tenantId,
        })
          .then(({ dataList }) => {
            let soundDuration = state.soundDuration;
            dataList.forEach((item) => {
              const { key, value } = item;
              if (key === "alarm_sound_duration") {
                soundDuration = value;
              }
            });
            commit("SET_SOUND_DURATION", soundDuration);
            resolve(soundDuration);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    initWebSocket({ state, commit, dispatch, rootGetters }) {
      let {
        tenantId,
        isolationOrgCode = "default",
        staffId,
      } = rootGetters.userInfo || {};
      const { host: _host, protocol: _protocol } = window.location;
      if (!isProd && !tenantId) tenantId = 100022;
      const host = isProd ? _host : "test1216.iot.cn";
      const protocol = _protocol === "http:" ? "ws" : "wss";
      const url = `${protocol}://${host}/monitor/alarm-push/${tenantId}/${isolationOrgCode}/${staffId}`;
      const createWebsocket = (url) => {
        try {
          state.websocket = new WebSocket(url);
          initEventHandler();
        } catch (error) {
          reconnect(url);
        }
      };
      createWebsocket(url);
      // 监听连接成功
      const initEventHandler = () => {
        state.websocket.onopen = () => {
          console.log("连接建立成功");
          heartCheck.reset().start();
        };
        // 监听服务端消息(接收消息)
        state.websocket.onmessage = (e) => {
          console.log("接收服务端返回数据", e);
          heartCheck.reset().start();
          dispatch("checkHeartBeat");
          if (e.data.indexOf("alarmCount") > -1) {
            const data = JSON.parse(e.data);
            const { alarmCount, alarmName } = data;
            commit("SET_ALARM_COUNT", alarmCount);
            if (alarmCount && alarmName) {
              if (state.notifyList.length > 4) {
                const instance = state.notifyList.shift();
                instance.close();
              }
              dispatch("showAlarmNotify", data);
            }
          }
          // dispatch("mockAlarmPush");
        };
        // 监听连接失败重连
        state.websocket.onerror = () => {
          console.log("连接建立失败");
          reconnect(url);
        };
        // 监听连接关闭
        state.websocket.onclose = (e) => {
          console.log("websocket断开连接", e);
          reconnect(url);
        };
      };

      const heartCheck = {
        timeout: 30000,
        timeoutObj: null,
        serverTimeoutObj: null,
        reset: function () {
          clearTimeout(this.timeoutObj);
          clearTimeout(this.serverTimeoutObj);
          return this;
        },
        start: function () {
          var self = this;
          this.timeoutObj = setTimeout(function () {
            state.websocket.send("ping");
            console.log("ping!");
            self.serverTimeoutObj = setTimeout(function () {
              state.websocket.close();
            }, self.timeout);
          }, this.timeout);
        },
      };

      function reconnect(url) {
        if (state.lockReconnect) return;
        state.lockReconnect = true;
        setTimeout(function () {
          createWebsocket(url);
          state.lockReconnect = false;
        }, 2000);
      }

      window.onbeforeunload = function () {
        state.websocket.close();
      };
    },
    checkHeartBeat({ state, dispatch }) {
      setTimeout(() => {
        if (state.websocket?.readyState !== 1) {
          dispatch("initWebSocket");
        } else {
          const time = Date.parse(new Date()); // 当前时间戳
          const data = JSON.stringify({ time });
          state.websocket.send(data);
        }
      }, 30 * 1000);
    },
    showAlarmNotify({ state, dispatch, commit }, data) {
      if (!state.isLock) {
        state.updateApiTimes += 1;
        state.isLock = true;
        setTimeout(() => {
          state.isLock = false;
        }, 60 * 1000);
      }
      //当前为运行看板页面
      if (router.app._route.path === "/run/run-board") {
        commit("SET_BOARD_UPDATE", true);
      }
      const {
        alarmName,
        deviceName = "--",
        alarmTime = "--",
        addressName = "--",
        soundAlarm = false,
      } = data;
      if (soundAlarm && state.soundDuration) {
        dispatch("stopAudio");
        dispatch("playAudio");
      }
      let showClose = true,
        customClass = "box-notify",
        icon = alarmIcon,
        duration = 0;
      // 当前为大屏页面
      if (router.app._route.path === "/big-screen") {
        // showClose = false;
        customClass = "box-notify screen";
        icon = alarmScreenIcon;
        // duration = 5000;
      }
      const instance = Notification({
        title: alarmName,
        dangerouslyUseHTMLString: true,
        message: `
        <div class="content">
          <div class="box-icon"><img class="icon" src="${icon}" /></div>
          <div class="info">
            <h3 class="name">${deviceName}</h3>
            <p class="time">${alarmTime}</p>
            <p class="address">${addressName}</p>
          </div>
        </div>`,
        duration,
        customClass,
        showClose,
        onClose: (e) => {
          const instanceIndex = state.notifyList.findIndex(
            (item) => item.id === e.id
          );
          if (instanceIndex !== -1) {
            state.notifyList.splice(instanceIndex, 1);
          }
          if (!state.notifyList?.length) {
            dispatch("stopAudio");
            clearInterval(state.soundTimer);
          }
        },
      });
      state.notifyList.push(instance);
    },
    clearWebSocket({ state }) {
      state.websocket && state.websocket.close();
    },
    // 循环播放音频
    handleSoundDuration({ state }) {
      clearInterval(state.soundTimer);
      const { soundDuration, audioDuration } = state;
      const duration = soundDuration * 60 * 1000;
      if (duration <= audioDuration) return;
      // 初始化当前播放音频时间
      let currentDuration = audioDuration;
      state.soundTimer = setInterval(() => {
        if (currentDuration < duration) {
          // 当前播放时间小于设置时间,继续播放且增加当前播放时间
          audio.play();
          currentDuration += audioDuration;
        } else {
          clearInterval(state.soundTimer);
        }
      }, audioDuration);
    },
    //播放音频
    playAudio({ state, dispatch }) {
      audio = new Audio();
      audio.src = mp3;
      audio.play();
      audio.oncanplay = function () {
        // 获取音频时长 + 200毫秒作为循环播放间隔时间
        const duration = Math.ceil(audio.duration * 1000) + 200;
        state.audioDuration = duration;
      };
      dispatch("handleSoundDuration");
    },
    //停止音频
    stopAudio() {
      audio && audio.pause();
    },
  },
};
