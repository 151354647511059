import request from "./request";
import { isProd } from "@/config";

export default (proxy = "/") => {
  const baseURL = isProd ? "/" : proxy;
  return {
    reqPUT: (data, url, pending = true) => {
      return request({
        method: "PUT",
        data,
        url,
        pending,
        baseURL,
      });
    },
    reqDELETE: (data, url, pending = true) => {
      return request({
        method: "DELETE",
        url,
        pending,
        baseURL,
      });
    },
    reqPOST: (data, url, pasteParam = false, pending = true, args = {}) => {
      const query = {
        ...args,
        method: "POST",
        url,
        pending,
        baseURL,
      };
      pasteParam ? (query.params = data) : (query.data = data);
      return request(query);
    },
    reqGET: (params, url, pasteData = false, pending = true) => {
      const query = {
        method: "GET",
        url,
        pending,
        baseURL,
      };
      pasteData ? (query.data = params) : (query.params = params);
      return request(query);
    },
  };
};
